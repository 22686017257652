@font-face {
  font-family: 'Wainer';
  src: url('./assets/fonts/wainer.ttf') format('truetype');
}
body {
  font-family: 'Roboto', sans-serif !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
